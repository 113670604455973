<template>
	<div>
		<v-card class="The_Style d-sm-flex flex-column d-none the-sidebar sidebar-left-nav" height="100vh" :width="Width">
			<v-row class="the-sidebar-tab flex-row mb-md-0 mb-sm-auto" align="center" v-on:click="collapse = !collapse">
				<transition name="fade" mode="out-in" tag="div">
					<v-img :src="$store.state.icons.icons['Blue@3x']" v-if="!collapse" height="24"></v-img>

					<img :src="$store.state.icons.icons['Logotype@3x']" v-if="collapse" style="height: 24px" alt />
				</transition>
				<!-- <img :src="$store.state.icons.icons['favicon-32x32']" 
                    style="margin-left:-5px"
                    /> 
                        <b v-if="collapse">
                            The <br> Build
                            </b>  
      -->
			</v-row>

			<div class="mt-sm-2 mt-xs-auto mt-2">
				<router-link tag="div" to="/home" active-class="active">
					<v-row class="the-sidebar-tab menu-tab flex-row" align="center">
						<img :src="$store.state.icons.icons['view-list']" class="sidebar-icons" alt />
						<transition name="fade" mode="out-in">
							<font v-if="collapse">Dashboard</font>
						</transition>
					</v-row>
				</router-link>
			</div>

			<router-link tag="div" to="/lists" active-class="active">
				<v-row class="the-sidebar-tab menu-tab flex-row" align="center" style="position: relative">
					<div v-if="this.$store.state.chathaggle.unreadMessageUserCount > 0" style="z-index: 9" dense no-gutters>
						<div class="notification-count d-flex align-center justify-center">
							{{ this.$store.state.chathaggle.unreadMessageUserCount }}
						</div>
					</div>
					<img :src="$store.state.icons.icons['Lists@3x']" class="sidebar-icons" alt />
					<transition name="fade" mode="out-in">
						<font v-if="collapse">Lists</font>
					</transition>
				</v-row>
			</router-link>

			<router-link tag="div" to="/deliveries" active-class="active">
				<v-row class="the-sidebar-tab menu-tab flex-row" align="center">
					<img :src="$store.state.icons.icons['DeliveryVan']" class="sidebar-icons" style="height: 22px" alt />
					<transition name="fade" mode="out-in">
						<font v-if="collapse">Deliveries</font>
					</transition>
				</v-row>
			</router-link>

			<router-link tag="div" to="/settings" active-class="active">
				<v-row class="the-sidebar-tab menu-tab flex-row mt-auto" align="center">
					<img :src="$store.state.icons.icons['Users@3x']" class="sidebar-icons" alt />
					<transition name="fade" mode="out-in">
						<font v-if="collapse">Settings</font>
					</transition>
				</v-row>
			</router-link>

			<v-row class="the-sidebar-tab menu-tab flex-row mt-auto" align="center" @click="Logout()">
				<img :src="$store.state.icons.icons['Logout']" class="sidebar-icons" style="height: 16px" alt />
				<transition name="fade" mode="out-in">
					<font v-if="collapse">Logout</font>
				</transition>
			</v-row>
		</v-card>

		<v-card class="The_Style d-sm-none d-block the-sidebar sidebar-left-nav" height="100vh" min-width="100vw" v-if="mobile_collapse">
			<v-row class="the-sidebar-tab flex-row mb-lg-0 mb-md-auto" align="center" style="align-content: center">
				<v-col name="fade" mode="out-in" tag="div" class="d-flex justify-space-between pt-3 px-0">
					<!-- <template> -->
					<img :src="$store.state.icons.icons['Logotype@3x']" style="height: 32px" />
					<i class="mdi mdi-close text-end" @click="mobile_collapse = !mobile_collapse" style="color: white; font-size: 25px" />
				</v-col>
			</v-row>

			<router-link tag="div" to="/home" active-class="active">
				<v-row class="the-sidebar-tab menu-tab flex-row" align="center">
					<img :src="$store.state.icons.icons['view-list']" class="sidebar-icons pr-3" alt />
					<transition name="fade" mode="out-in">
						<font>Dashboard</font>
					</transition>
				</v-row>
			</router-link>

			<router-link tag="div" to="/lists" active-class="active">
				<v-row class="the-sidebar-tab menu-tab flex-row" align="center" style="position: relative">
					<div style="z-index: 9" dense no-gutters>
						<div v-if="this.$store.state.chathaggle.unreadMessageUserCount > 0" class="notification-count d-flex align-center justify-center">
							{{ this.$store.state.chathaggle.unreadMessageUserCount }}
						</div>
					</div>
					<img :src="$store.state.icons.icons['Lists@3x']" class="sidebar-icons pr-3" alt />
					<transition name="fade" mode="out-in">
						<font>Lists</font>
					</transition>
				</v-row>
			</router-link>

			<router-link tag="div" to="/deliveries" active-class="active">
				<v-row class="the-sidebar-tab menu-tab flex-row" align="center">
					<img :src="$store.state.icons.icons['DeliveryVan']" class="sidebar-icons pr-3" style="height: 22px" alt />
					<transition name="fade" mode="out-in">
						<font>Deliveries</font>
					</transition>
				</v-row>
			</router-link>

			<router-link tag="div" to="/settings" active-class="active">
				<v-row class="the-sidebar-tab menu-tab flex-row mt-auto" align="center">
					<img :src="$store.state.icons.icons['Users@3x']" class="sidebar-icons pr-3" alt />
					<transition name="fade" mode="out-in">
						<font>Settings</font>
					</transition>
				</v-row>
			</router-link>

			<v-row class="the-sidebar-tab menu-tab flex-row mb-0" align="center" @click="Logout()">
				<img :src="$store.state.icons.icons['Logout']" class="sidebar-icons pr-3" style="height: 16px" alt />
				<transition name="fade" mode="out-in">
					<font>Logout</font>
				</transition>
			</v-row>
			<v-col cols="12" class="d-sm-none d-block">
				<div class="d-flex align-center">
					<v-avatar color="#BDBDBD">
						<img
							:src="$store.state.icons.icons['user-circle']"
							style="
								width: 70%;
								height: 70%;
								filter: brightness(0) saturate(100%) invert(98%) sepia(2%) saturate(0%) hue-rotate(231deg) brightness(103%) contrast(102%);
							"
							alt
						/>
						<!-- <i class="fa fa-user-circle-o"></i> -->
					</v-avatar>

					<span class="heading-name pl-4">{{ this.$store.state.auth.user_data.first_name }} {{ this.$store.state.auth.user_data.last_name }}</span>
				</div>
			</v-col>
		</v-card>
	</div>
</template>

<script>
import { userlogout } from '../../services/authservices'
import Cookies from 'js-cookie'
import firebase from 'firebase/app'
import { FIREBASE_COLLECTION_NAME_PREFIX } from '../../config/variable'
export default {
	data() {
		return {
			collapse: false,
			width: 80,
			class_active: '',
			mobile_collapse: true,
		}
	},

	async mounted() {
		await this.$store.dispatch('getCount')
	},

	computed: {
		Width() {
			this.collapse === true ? (this.width = 200) : (this.width = 80)
			return this.width
		},
	},
	methods: {
		async Logout() {
			let docRef = await firebase
				.firestore()
				.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'users')
				.doc(`${this.$store.state.auth.user_data.company_id}DC`)
			docRef.update('isUserOnline', false)

			const userFcm = await firebase
				.firestore()
				.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'users')
				.doc(`${this.$store.state.auth.user_data.company_id}DC`)
				.get()

			let fcmIdList = userFcm.data().fcm_id

			fcmIdList = fcmIdList.filter(function (e) {
				return e !== Cookies.get('fcm_id')
			})

			if (Cookies.get('fcm_id') !== undefined) {
				// await messaging
				docRef.update('fcm_id', fcmIdList)
			}
			// this.$store.dispatch("authLogout");
			await userlogout()
			location.reload()
		},

		classActive() {
			if (this.$router.currentRoute.fullPath === '/home' || this.$router.currentRoute.fullPath === '/') {
				this.class_active = 'home'
			} else if (this.$router.currentRoute.fullPath === '/lists') {
				this.class_active = 'lists'
			} else if (this.$router.currentRoute.fullPath === '/deliveries') {
				this.class_active = 'deliveries'
			} else if (this.$router.currentRoute.fullPath === '/settings') {
				this.class_active = 'settings'
			}
		},
	},
}
</script>

<style scoped lang="scss">
.the-sidebar {
	background: #162a51 !important;
	box-shadow: 0 12px 44px 0 rgba(75, 109, 130, 0.11) !important;
	border-radius: 0px !important;
	padding: 0px;

	transition: width 0.3s linear, transform 0.3s ease-in-out;

	.the-sidebar-tab {
		max-height: 80px !important;
		padding: 28px;
		margin: 0px;
		cursor: pointer;
	}

	.sidebar-icons {
		filter: brightness(0) saturate(100%) invert(72%) sepia(5%) saturate(3438%) hue-rotate(184deg) brightness(95%) contrast(86%);
		height: 24px;
	}

	.menu-tab {
		background: #203765 !important;
		border-bottom: 1px solid #162a51;
	}

	.active {
		.menu-tab {
			background: #009ffd !important;
		}

		.sidebar-icons {
			filter: brightness(0) saturate(100%) invert(98%) sepia(2%) saturate(0%) hue-rotate(231deg) brightness(103%) contrast(102%);
			height: 24px;
		}
	}

	b {
		padding-top: 8px;
		font-family: Inter !important;
		font-size: 11px !important;
		color: #fbfbfb !important;
		line-height: 11px;
	}

	font {
		font-family: Inter-Medium;
		font-size: 15px !important;
		color: #fbfbfb !important;
		margin-left: 28px;
	}

	.sidebar-left-nav {
		transition: all 0.5s ease 0s;
		-webkit-transition: all 0.5s ease 0s;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition-delay: 2s !important;
		transition: opacity 2s !important;
	}

	.fade-enter,
	.fade-leave-to {
		transition: opacity 0.1s !important;
	}

	.notification-count {
		background: #d5704d !important;
		border-radius: 50% !important;
		font-size: 14px;
		color: #ffffff;
		padding: 2px 4px;
		min-width: 24px;
		position: absolute;
		right: 10px;
		top: 10px;
	}

	@media only screen and (max-width: 600px) {
		.the-sidebar {
			min-width: 500px !important;
		}
	}
}
</style>
